<template>
</template>

<script>
export default {
    name: 'Logout',
    mounted() {
        localStorage.setItem('apiToken', null)
        localStorage.setItem('apiUserToken', null)

        window.location.href = "/v2";
    },
    methods: {},
}
</script>